<template>
  <div style="position: relative">
    <div class="content-title">
      {{ $g("comment.comments") }}
    </div>
    <div class="content-table-group">
      <konn-grid
        id="grid"
        :has-check-box="false"
        :has-action="false"
        ref="selectableTable"
        :data-source="dataSource"
        :get-condition="getCondition"
        :show-all-checkbox="false"
        :fields="fields"
      />
    </div>
  </div>
</template>

<script>
import { getComments } from "@/api/workAssignments";
export default {
  props: {
    refId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: [
        {
          key: "createTime",
          label: "comment.columns.date",
          width: "15%",
        },
        {
          key: "officer",
          label: "comment.columns.officer",
          width: "15%",
        },
        {
          key: "comment",
          label: "comment.columns.comment",
        },
      ],
    };
  },
  computed: {
    dataSource() {
      return getComments;
    },
  },
  methods: {
    getCondition() {
      return { refId: this.refId };
    },
  },
};
</script>

<style lang="scss" scoped>
.hand:hover {
  cursor: pointer;
}
</style>
