<template>
  <div class="content-page">
    <div class="content-form" ref="form">
      <div class="content">
        <div class="center-bar">
          <slot name="formBar" />
          <div class="content-main">
            <slot name="formMsg" />
            <div class="content-party pt-3">
              <div
                v-for="(contact, index) in items.contactDetails"
                :key="contact.title"
              >
                <div class="content-title">
                  {{ contact.title }}
                </div>
                <div class="content-group">
                  <template v-for="(item, index) in items.fields">
                    <k-form-group
                      :label-suffix="item.field"
                      label-for="input-1"
                      label-align-md="right"
                      label-cols-md="3"
                      content-cols-lg="6"
                      content-cols-md="7"
                    >
                      <label class="mt-2" v-if="!isOnOff(item.field)">
                        {{ contact[item.field] }}
                      </label>
                      <label class="mt-2" v-else>
                        {{ getOnOff(contact[item.field]) }}
                      </label>
                    </k-form-group>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getViewMoreInformation } from "@/api/workAssignments";
export default {
  props: {
    electionId: {
      type: String,
      default() {
        return null;
      },
    },
    eleFacilityId: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getViewMoreInformation({
        electionId: this.electionId,
        eleFacilityId: this.eleFacilityId,
      }).then((result) => {
        this.items = result.data;
      });
    },
    isOnOff(s) {
      let d = [
        "emailPreference",
        "smsPreference",
        "phonePreference",
        "isUseNoDisturbHours",
      ];
      return d.findIndex((c) => c == s) > -1;
    },
    getOnOff(i) {
      return i == 1 ? "OFF" : "ON";
    },
  },
};
</script>

<style>
</style>