<template>
  <div class="wapModel layoutTableCard">
    <div v-for="item in items" :key="item.id" class="tableGroup">
      <div class="tableList justify-content-between">
        {{ item.fullName }}
        <div>
          <k-link :href="`tel:${item.phone}`" class="mr-2">
            <b-icon icon="telephone-fill" class="text-red" />
            <span v-show="false">WCAG</span>
          </k-link>
          <k-link :href="`mailto:${item.email}`">
            <b-icon icon="envelope-fill" class="text-green" />
            <span v-show="false">WCAG</span>
          </k-link>
        </div>
      </div>
      <div class="tableList">
        <div class="control-field">{{ fields[1].label }}:&nbsp;</div>
        <div class="control-value">
          <div v-for="(item, index) in item['positionList']" :key="index">
            {{ item.eleJobTitle }}
          </div>
        </div>
      </div>
      <div class="tableList">
        <div class="control-field">{{ fields[2].label }}:&nbsp;</div>
        <div class="control-value">
          <div v-for="(item, index) in item['scheduleList']" :key="index">
            {{ item.workDateStr }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
};
</script>